<template>
  <div class="olimp-admin-form accountModal">
    <div class="account-info" v-if="accountObj">
      <div>
        <h6 class="text-gray-5 font-weight-bold mb-3">Personal information</h6>
        <a-row>
          <a-col :span="8">
            <div>
              <label class="d-block">Phone</label>
              <span v-if="accountObj.phone && accountObj.phone_last4">
                <span v-if="decryptedData.phone">
                  {{ decryptedData.phone }}
                  <a href="javascript:;">
                    <a-icon type="eye" class="text-primary" @click="encryptField('phone')"  />
                  </a>
                  <a-tooltip title="Copy to clipboard" placement="bottom">
                    <a href="javascript:;" class="ml-2"><a-icon type="copy" class="text-primary" @click="clipboardHandle(decryptedData.phone, 'Phone')" /></a>
                  </a-tooltip>
                </span>
                <span v-else>
                  *{{ accountObj.phone_last4 }}
                  <a href="javascript:;" v-if="decryptionKey && !decryptedData.phone">
                    <a-icon type="eye-invisible" class="text-primary" @click="decryptField('phone', accountObj.phone)"  />
                  </a>
                </span>
              </span>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="mt-4">
        <h6 class="text-gray-5 font-weight-bold mb-3">App information</h6>
        <a-row>
          <a-col :span="8">
            <div>
              <label class="d-block">Platform</label>
              <span>
                <a-icon v-if="accountObj.toLowerCase() === 'android'" type="android" class="text-success" />
                <a-icon v-if="accountObj.toLowerCase() === 'ios'" type="apple" class="text-primary" />
                <a-icon v-if="accountObj.toLowerCase() === 'web'" type="global" class="text-default" />
                {{ accountObj.type }}
              </span>
            </div>
          </a-col>
          <a-col :span="8">
            <div>
              <label class="d-block">Bundle ID</label>
              <span>{{ accountObj.bundle_id }}</span>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="account-info__footer">
      <hr>
      <div class="d-flex justify-content-end">
        <a-button key="submit" type="primary" @click="closeModal">
          OK
        </a-button>
      </div>
    </div>
    <div v-if="loading" class="banners-loading-spinner text-center">
      <a-icon type="loading" class="text-primary" />
    </div>
  </div>
</template>

<script>
import accessMix from '@/services/accessmix'

export default {
  name: 'accountModal',
  props: ['modalDataUser'],
  mixins: [accessMix],
  computed: {
    decryptionKey() {
      return !!sessionStorage.getItem('app.encryption.privatekey')
    },
  },
  data() {
    return {
      loading: false,
      accountObj: undefined,
      decryptedData: {
        phone: null,
        email: null,
      },
    }
  },
  async mounted () {
    this.accountObj = this.modalDataUser
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    clipboardHandle(v, field) {
      this.$clipboard(v)
      this.$notification.success({
        message: field + ' copied to clipboard',
        description: v.slice(0, 8) + '...',
      })
    },
    decryptField(field, value) {
      const decKey = sessionStorage.getItem('app.encryption.privatekey')
      if (!decKey) {
        this.$message.error('Decryption key not found in session storage')
        return
      }
      const dec = this.$decryptData(decKey, value)
      if (dec) {
        this.decryptedData[field] = dec
      } else {
        this.$message.error('Data decryption failed due to invalid key or data content')
      }
    },
    encryptField(field) {
      this.decryptedData[field] = null
    },
  },
}
</script>

<style lang="scss">
.account-info {
  &__footer {
    margin-left: -48px;
    margin-right: -48px;
    padding: 10px 24px 0px 24px;
    & > div {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
</style>
