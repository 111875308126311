<template>
  <div class="users-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff;">
      <a-col :span="12">
        <h3 class="my-2" style="font-size: 20px;">Whitelist</h3>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-button
          type="primary"
          size="large"
          style="font-size: 16px; font-weight: 300; border-radius: 2px;"
          @click="addUser"
        >
          <a-icon type="plus" /> Add user
        </a-button>
      </a-col>
    </a-row>
    <a-tabs type="card">
      <a-tab-pane key="1" tab="Whitelist">
        <accepted-users
          ref="whitelist-users"
          :addUserEvent="addUserEvent"
        />
      </a-tab-pane>
      <a-tab-pane key="2" :tab="totalUsersRequest ? `Requests (${totalUsersRequest})` : 'Requests'">
        <requests-user
          ref="request-users"
          @totalUsersRequests="totalUsersRequests"
          @getWhitelist="getWhitelist"
        />
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model="addUserModal"
      title="Add user"
      ok-text="Add"
      cancel-text="Cancel"
      @ok="sendFormAddUser"
      width="520px"
      centered
      :destroyOnClose="true"
    >
      <a-form-model
        ref="addUserForm"
        :model="addUserForm"
        :rules="addUserRules"
      >
        <a-form-model-item ref="userPhone" label="User phone" prop="userPhone">
          <a-textarea
            v-model="addUserForm.userPhone"
            :rows="4"
            placeholder="Phone number"
            @keypress="keypressPhoneNumbers($event, addUserForm.userPhone)"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import acceptedUsers from './components/acceptedUsers.vue'
import requestsUser from './components/requestsUsers.vue'

export default {
  name: 'index',
  components: {
    acceptedUsers,
    requestsUser,
  },
  mixins: [accessMix],
  watch: {
    'addUserForm.userPhone'(val) {
      this.$nextTick(() => {
        this.replacePhones()
      })
    },
  },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      addUserModal: false,
      addUserForm: {
        userPhone: '',
      },
      addUserRules: {
        userPhone: [{ required: true, message: 'Please input phone', trigger: 'change' }],
      },
      addUserEvent: false,
      loading: false,
      totalUsersRequest: null,
      decryptionKey: !!sessionStorage.getItem('app.encryption.privatekey'),
    }
  },
  methods: {
    totalUsersRequests(totalUsersRequest) {
      this.totalUsersRequest = totalUsersRequest
    },
    getWhitelist() {
      if (this.$refs['whitelist-users']) {
        this.$refs['whitelist-users'].fetch()
      }
    },
    getTotalRequestUsers() {
      const url = '/admin/accounts/requested/count'
      apiClient.get(url).then((res) => {
        this.totalUsersRequest = res.data.data.count
      }).catch(error => {
        this.$notification.error({
          message: 'Error getting number of users',
          description: error.message,
        })
      })
    },
    addUser() {
      this.addUserModal = true
    },
    keypressPhoneNumbers(e) {
      if (e.key === 'Enter') {
        return false
      }
      if (!/^\d+$/.test(e.key)) {
        return e.preventDefault()
      }
    },
    replacePhones() {
      const arrPhoneNumbers = this.addUserForm.userPhone.split('\n')
      const arrСlearPhoneNumbers = arrPhoneNumbers.map(phone => {
        return phone.replace(/[^\d]/g, '')
      })

      this.addUserForm.userPhone = arrСlearPhoneNumbers.join('\n')
    },
    getArrUserNumbers() {
      const arrPhoneNumbers = []
      const userPhoneArr = this.addUserForm.userPhone.split('\n')

      userPhoneArr.forEach((phone) => {
        if (phone.length) {
          arrPhoneNumbers.push(Number(phone.trim()))
        }
      })
      return arrPhoneNumbers
    },
    sendFormAddUser() {
      this.$refs.addUserForm.validate(valid => {
        if (valid) {
          const arrPhoneNumbers = this.getArrUserNumbers()

          const url = '/admin/accounts/whitelisted/mass'
          apiClient.post(url, arrPhoneNumbers).then((response) => {
            this.addUserEvent = true
            this.addUserForm.userPhone = ''
            this.$notification.success({
              message: 'Request successfully',
              description: '',
            })
          }).catch(error => {
            this.$notification.error({
              message: 'Error add user',
              description: error.message,
            })
          }).finally(() => {
            this.addUserModal = false
            setTimeout(() => {
              this.addUserEvent = false
            }, 350)
          })
        }
      })
    },
  },
  mounted() {
    this.getTotalRequestUsers()
  },
}
</script>

<style lang="scss">
.close-circle-search {
  color: rgba(0,0,0,.45);
  transition: all .3s ease;
  &:hover {
    color: rgba(0,0,0,.9);
    cursor: pointer;
  }
}
.users-content .ant-tabs-bar {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  border-bottom: 0;
}
.users-content .ant-tabs-nav .ant-tabs-tab {
  margin-right: 2px !important;
  padding: 0 32px !important;
}
.footer-actions {
  &__mt {
    margin-top: -52px;
  }
}
</style>
